import {mapGetters, mapActions, mapMutations} from 'vuex';
import {assign, cloneDeep} from "lodash";

export default {
  name: 'parcelsNew',
  data() {
    return {}
  },
  components: {},
  created() {
    if (!('page' in this.$route.query)) {
      this.setUrlParams({page: 1});
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      parcelsNew: 'parcels/parcelsNew',
      parcelsNewPage: 'parcels/parcelsNewPage',
      parcelsNewPageLength: 'parcels/parcelsNewPageLength',
      isParcelsNewLoading: 'parcels/isParcelsNewLoading',
      isParcelUpdating: 'parcels/isParcelUpdating',
    }),
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler: function (val) {
        if (val.page) {
          this.setQueryParams();
          this.fetchNewParcels(this.queryParams);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchNewParcels: 'parcels/PARCELS_REQUEST_NEW_LIST_GET',
      updateParcelStatus: 'parcels/PARCELS_REQUEST_DATA_UPDATE'
    }),
    ...mapMutations({}),
    setUrlParams(query = {}) {
      this.$router.push({name: 'parcels.list', query: assign({}, this.$route.query, query)}).catch(() => {
        console.log()
      });
    },
    setQueryParams(){
      this.queryParams = cloneDeep(this.$route.query);
    },

    getPage(pageId) {
      this.setUrlParams({page: pageId})
    },

    show(parcel) {
      this.$router.push({name: 'orders.show', params: {orderId: parcel.order.data.id}}).catch(() => {console.log()});
    },

    getInWork(parcel) {
      this.$set(parcel, 'isLoading', true);
      this.updateParcelStatus({
        parcelId: parcel.id,
        payload: {
          status: 'collecting'
        }
      }).then(() => {
        this.$toasted.success(this.$t('parcelGetInWork'));
        this.$set(parcel, 'isChange', false);
        this.$router.push({name: 'orders.show', params: {orderId: parcel.order.data.id}}).catch(() => {console.log()});
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      }).finally(()=>{
        this.$set(parcel, 'isLoading', false);
      });
    },
  },
  destroyed() {},
}
