import {mapGetters, mapActions, mapMutations} from 'vuex';

import {createCSVAddresses, createCSVProducts} from "@/utils/csv";
import {uniqBy} from "lodash";

export default {
  name: 'parcelsCsvs',
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({}),

    saveAddresses() {
      let addresses = this.list.map(parcel => {return parcel.order.data.address});
      createCSVAddresses(addresses);
    },
    saveProducts() {
      let products = [];

      this.list.forEach(parcel => {
        parcel.boxes.data.forEach(box => {
          box.box_product.data.forEach(box_product_item => {
            products.push(box_product_item.product.data)
          })
        })
      });
      products = uniqBy(products, 'id');
      createCSVProducts(products);
    },

  },
  destroyed() {},
}
