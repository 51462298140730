import {mapGetters, mapMutations, mapActions} from 'vuex'
import {formatBarcode} from "@/utils/barcode";
import {cloneDeep} from "lodash";

export default {
  name: 'sentParcel',
  props: {
    list: {
      type: Array,
      required: true,
      default() {
        return []
      },
    }
  },
  //---components
  components: {},
  //---data
  data () {
    return {
      isShow: null,
      parcels: [],
      parcelData: {
        currentIndex: 0,
        list: [],
        keyArray: [],
        data: null,
        isLoading: false
      },
      boxData: {
        currentIndex: 0,
        list: [],
        keyArray: [],
      },
      isShowDatePopup: false,
      isSomeParcelSent: false,
      validCodeTypes: ['PL','PA']
    }
  },
  //---hooks
  created() {
    this.isShow = true;
    this.parcels = cloneDeep(this.list);
    this.fillData(this.parcels[this.parcelData.currentIndex]);
  },
  mounted() {
    this.setInputFocus('boxBarcodeInput',this.boxData.currentIndex);
  },
  computed: {
    ...mapGetters({
      popupStore: 'system/popupStore',
    }),
    isAllParcelsSent() {
      return this.parcelData.currentIndex + 1 > this.parcels.length;
    },
    isBoxAllChecked() {
      // return this.parcelData.currentIndex + 1 > this.parcels.length;
      return this.boxData.currentIndex + 1 > this.boxData.list.length
    }
  },
  watch: {},
  //---methods
  methods: {
    ...mapMutations({
      closePopup: 'system/SYSTEM_POPUP_CLOSE',
    }),
    ...mapActions({
      updateParcelStatus: 'parcels/PARCELS_REQUEST_DATA_UPDATE'
    }),

    //---general
    close() {
      this.isShow = false;
      if (this.isSomeParcelSent) {
        this.$emit('parcel-sent')
      }
      setTimeout(()=>{
        this.closePopup('sentParcel');
      },400);
    },

    getBoxTitle(data) {
      return data.box_type.data.title;
    },

    getBoxPlace(data) {
      return `${data.storage.rack.title}, ${data.storage.shelf.title}, ${data.storage.place.title}`;
    },

    fillData(parcel){
      this.parcelData.data = parcel;
      this.parcelData.list = [];
      this.boxData.list = [];
      this.boxData.currentIndex = 0;
      parcel.boxes.data.forEach(box => {
        this.boxData.list.push({
          barcode: null,
          data: box,
          isFetched: false,
          isMatched: false
        });
        this.parcelData.list.push({
          barcode: null,
          isFetched: false,
          isMatched: false
        });
      });
    },

    //---barcode
    parseBarcode(code, codeType) {
      let barcodeString = null,
        parts = code.toUpperCase().split('-'),
        type = parts[0],
        id = parseInt(parts[1]);

      if (id && this.validCodeTypes.indexOf(type) >= 0 ) {
        if (codeType === type) {
          barcodeString = code;
        } else {
          this.$toasted.error(this.$t('barcodeInvalidType'));
        }
      } else {
        this.$toasted.error(this.$t('barcodeNotRecognized'));
      }
      return {id, barcodeString}
    },

    setInputFocus(name, index = null) {
      setTimeout(() => {
        if (index !== null) {
          this.$refs[name][index].focus();
        } else {
          this.$refs[name].focus();
        }
      },100);
    },

    keyEventBoxReading($event, index) {
      if ($event.keyCode === 13){
        let {id: placeId, barcodeString: placeBarcode } = this.parseBarcode(formatBarcode(this.boxData.keyArray), 'PL');
        this.boxData.keyArray = [];
        this.boxData.currentIndex = index;

        if (placeBarcode) {
          this.boxData.list[this.boxData.currentIndex].barcode = placeBarcode;
          this.checkPlaceBarcode(placeId);
        } else {
          this.boxData.list[this.boxData.currentIndex].isFetched = true;
          this.boxData.list[this.boxData.currentIndex].isMatched = false;
        }
      } else {
        this.boxData.keyArray.push($event);
      }
    },

    checkPlaceBarcode(placeId) {
      this.boxData.list[this.boxData.currentIndex].isFetched = true;

      let isMatched = placeId === this.boxData.list[this.boxData.currentIndex].data.place.data.id;
      this.boxData.list[this.boxData.currentIndex].isMatched = isMatched;

      if (isMatched) {
        this.setInputFocus('parcelBarcodeInput', this.boxData.currentIndex);
      } else {
        this.$toasted.error(this.$t('barcodeBoxPlaceNotFoundInParcel'));
      }
    },

    keyEventParcelReading($event) {
      if ($event.keyCode === 13){
        let {id: parcelId, barcodeString: productBarcode } = this.parseBarcode(formatBarcode(this.parcelData.keyArray), 'PA');
        this.parcelData.keyArray = [];

        if (productBarcode) {
          this.parcelData.list[this.boxData.currentIndex].barcode = productBarcode;
          this.checkParcelBarcode(parcelId);
        } else {
          this.parcelData.list[this.boxData.currentIndex].isFetched = true;
          this.parcelData.list[this.boxData.currentIndex].isMatched = false;
        }
      } else {
        this.parcelData.keyArray.push($event);
      }
    },

    checkParcelBarcode(parcelId) {
      this.parcelData.list[this.boxData.currentIndex].isFetched = true;
      let isMatched = this.parcelData.data.id === parcelId;
      this.parcelData.list[this.boxData.currentIndex].isMatched = isMatched;

      if (isMatched) {
        this.$toasted.success(this.$t('barcodeBoxFoundInParcel'));
        this.boxData.currentIndex++
        if (this.isBoxAllChecked) {
          this.sentParcel();
        } else {
          this.setInputFocus('boxBarcodeInput', this.boxData.currentIndex);
        }
      } else {
        this.$toasted.error(this.$t('barcodeBoxNotFoundInParcel'));
      }
    },

    //---additional
    sentParcel() {
      this.parcelData.isLoading = true;
      this.updateParcelStatus({
        parcelId: this.parcelData.data.id,
        payload: {
          status: 'sent'
        }
      }).then(() => {
        this.parcels[this.parcelData.currentIndex].status.data.name = 'sent';
        this.$toasted.success(this.$t('parcelSent'));
        this.isSomeParcelSent = true;
        this.parcelData.currentIndex++;
        if (!this.isAllParcelsSent) {
          this.gotoNextParcel();
        }
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      }).finally(()=>{
        this.parcelData.isLoading = false
      });
    },
    gotoNextParcel() {
      this.fillData(this.parcels[this.parcelData.currentIndex])
      this.setInputFocus('boxBarcodeInput',this.boxData.currentIndex);
    }
  },
  destroyed() {}
}