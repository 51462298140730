import {mapGetters, mapActions, mapMutations} from 'vuex';

import parcelsCsvs from '../parcels-csvs/index.vue';
import sentParcel from '@/components/popups/sent-parcel/index.vue';

import {assign, cloneDeep, findIndex, find} from "lodash";

export default {
  name: 'parcelsReady',
  data() {
    return {
      currentDeliveryMethod: '',
      checkedParcels: []
    }
  },
  components: {
    parcelsCsvs,
    sentParcel
  },
  created() {
    if (!('page' in this.$route.query)) {
      this.setUrlParams({page: 1});
    } else {
      if (this.$route.query.delivery_method) {
        this.currentDeliveryMethod = this.$route.query.delivery_method;
      }
      this.setUrlParams();
      this.fetchReadyParcels(this.$route.query);
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      parcelsReady: 'parcels/parcelsReady',
      parcelsReadyPage: 'parcels/parcelsReadyPage',
      parcelsReadyPageLength: 'parcels/parcelsReadyPageLength',
      isParcelsReadyLoading: 'parcels/isParcelsReadyLoading',
      deliveryList: 'delivery/deliveryList',
      popupStore: 'system/popupStore',
    }),
    decoratedParcels() {
      let list = [];
      if (this.parcelsReady) {
        list = this.parcelsReady.map(parcel => {
          this.$set(parcel, 'isParcelForSent', findIndex(this.checkedParcels, item => {return item.id === parcel.id}) > -1);
          this.$set(parcel, 'isAllBoxesHasDeliveryCode', parcel.boxes.data.every(box => {return box.delivery_code && box.delivery_code.length}));
          this.$set(parcel, 'isAllBoxesArePlaced', parcel.boxes.data.every(box => {return box.place.data}));
          return parcel;
        });
      }
      return list
    }
  },
  watch: {
    '$route.query': {
      handler: function (val) {
        if (val.page) {
          this.setQueryParams();
          this.fetchReadyParcels(this.queryParams);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchReadyParcels: 'parcels/PARCELS_REQUEST_READY_LIST_GET',
    }),
    ...mapMutations({
      openPopup: 'system/SYSTEM_POPUP_OPEN',
    }),

    getDeliveryTitle(deliveryId) {
      let delivery = find(this.deliveryList, function (item) {return item.id === deliveryId})
      return delivery ? delivery.title : '';
    },
    getDeliveryCodes(item) {
      return item.boxes.data
        .filter(box => {return box.delivery_code && box.delivery_code.length})
        .map(box=>{return box.delivery_code});
    },

    setUrlParams(query = {}) {
      let oldQuery = cloneDeep(this.$route.query);
      if ('delivery_method' in query && !query.delivery_method) {
        delete query.delivery_method
        delete oldQuery.delivery_method
      }

      this.$router.push({name: 'parcels.list', query: assign({}, oldQuery, query)}).catch(() => {
        console.log()
      });
    },
    setQueryParams(){
      this.queryParams = cloneDeep(this.$route.query);
    },

    getPage(pageId) {
      this.setUrlParams({page: pageId})
    },

    changeDeliveryMethod() {
      this.setUrlParams({
        page: 1,
        delivery_method: this.currentDeliveryMethod,
      });
    },

    show(parcel) {
      this.$router.push({name: 'orders.show', params: {orderId: parcel.order.data.id}}).catch(() => {console.log()});
    },

    toggleParcelMark(parcel) {

      if (!parcel.isAllBoxesHasDeliveryCode) {
        this.$toasted.error(this.$t('parcelSomeBoxesNoDeliveryCode'));
      } else if (!parcel.isAllBoxesArePlaced) {
        this.$toasted.error(this.$t('parcelSomeBoxesNoPlace'));
      } else {
        parcel.isParcelForSent = !parcel.isParcelForSent;
        let index = findIndex(this.checkedParcels, item => {return item.id === parcel.id});

        if (index > -1) {
          this.checkedParcels.splice(index, 1);
        } else {
          this.checkedParcels.push(parcel);
        }
      }
    },
    sentParcels() {
      this.openPopup('sentParcel');
    },
    updateList() {
      this.checkedParcels = [];
      this.setQueryParams();
      if (parseInt(this.queryParams.page) !== 1) {
        this.getPage(1);
      } else {
        this.fetchReadyParcels(this.queryParams);
      }
    }
  },
  destroyed() {},
}
