import {mapGetters, mapActions, mapMutations} from 'vuex';
import {assign, cloneDeep} from "lodash";

export default {
  name: 'parcelsCollecting',
  data() {
    return {}
  },
  components: {},
  created() {
    if (!('page' in this.$route.query)) {
      this.setUrlParams({page: 1});
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      parcelsCollecting: 'parcels/parcelsCollecting',
      parcelsCollectingPage: 'parcels/parcelsCollectingPage',
      parcelsCollectingPageLength: 'parcels/parcelsCollectingPageLength',
      isParcelsCollectingLoading: 'parcels/isParcelsCollectingLoading',
    }),
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler: function (val) {
        if (val.page) {
          this.setQueryParams();
          this.fetchCollectingParcels(this.queryParams);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchCollectingParcels: 'parcels/PARCELS_REQUEST_COLLECTING_LIST_GET',
    }),
    ...mapMutations({}),
    setUrlParams(query = {}) {
      this.$router.push({name: 'parcels.list', query: assign({}, this.$route.query, query)}).catch(() => {
        console.log()
      });
    },
    setQueryParams(){
      this.queryParams = cloneDeep(this.$route.query);
    },

    getPage(pageId) {
      this.setUrlParams({page: pageId})
    },

    show(parcel) {
      this.$router.push({name: 'orders.show', params: {orderId: parcel.order.data.id}}).catch(() => {console.log()});
    },
  },
  destroyed() {},
}
