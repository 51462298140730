import {mapGetters, mapActions, mapMutations} from 'vuex';

import parcelsNew from './components/parcels-new/index.vue';
import parcelsCollecting from './components/parcels-collecting/index.vue';
import parcelsReady from './components/parcels-ready/index.vue';
import parcelsSent from './components/parcels-sent/index.vue';

import {assign, isEmpty, findIndex} from 'lodash'

export default {
  name: 'parcels.list',
  data() {
    return {
      currentParcelType: 0,
      activatedTab: 'new',
      tabs: ['new','collecting','ready','sent']
    }
  },
  components: {
    parcelsNew,
    parcelsCollecting,
    parcelsReady,
    parcelsSent,
  },
  created() {
    if (isEmpty(this.$route.query)) {
      this.$router.replace({name: 'parcels.list', query: assign({}, { status: this.activatedTab })}).catch(() => {console.log()});
    } else {
      this.activatedTab = this.$route.query.status;
      this.currentParcelType = findIndex(this.tabs, tab => {
        return tab === this.$route.query.status;
      });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    activateTab(type) {
      this.activatedTab = type;
      this.$router.push({name: 'parcels.list', query: assign({}, {status: type})}).catch(() => {console.log()});
    },
  },
  destroyed() {},
}
